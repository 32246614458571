import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'
import { GalleryComponentDigitalCity } from '../industries/digitalcity'
import { DownloadOnTheAppStoreButton, GetItOnGooglePlayButton } from '../../components/icons/Social'

const ShowcaseWeRideTodayPage = () => (
  <Layout className='bg-primary-2 text-primary-3'>
    <Analytics />
    <SEO
      title='WeRide.Today'
      pageUrl='/showcase/greenbalkans/'
      description='Safe and Smart Active Urban Mobility'
      cardImage='/img/tumbacardweridetoday.png'
    />
    <LdJsonLogo />
    <MainWithMenu className='grid'>
      <Header className='z-20 pt-6 pb-24'>
        <HeaderLink className='text-primary-1' />
        <div className='py-12 text-left'>
          <h1 className='py-4 text-4xl lg:text-5xl text-primary-3 text-shadow'>WeRide.Today</h1>
          <p className='text-lg lg:text-xl text-shadow'>Safe and Smart Active Urban Mobility</p>
        </div>
      </Header>
      <BackgroundWithGrid image='weride-hero' gridRow={1} />
      <Deco className='z-10 bg-primary-2' decoType={2} before after gridRow={2} />
      <Section className='z-20' gridRow={2}>
        <h2 className='text-2xl lg:text-3xl text-primary-1'>Revolutionizing Urban Commuting</h2>
        <div className='lg:mr-48'>
          {/*<h3 className='mt-4 text-lg text-primary-1'>Empowering Conservation Efforts with Technology</h3>*/}
          <p className='mt-2 font-normal'>
            At Tumba Solutions, we envisioned a future where urban commuting is not just a necessity, but a joyful,
            safe, and community-driven experience. <strong className='font-normal text-primary-1'>WeRide.Today</strong>
            {' '} was born from a simple yet powerful idea: to create a digital product that brings together urban
            cyclists, pedestrians, commuters, and enthusiasts, offering everything from safe route recommendations to
            community-driven insights and rewards.
          </p>
          <h3 className='mt-4 text-lg text-primary-1'>The Challenge</h3>
          <p className='mt-2 font-normal'>Urban commuters face numerous obstacles in their daily journeys:</p>
          <ul className='pl-10 mt-2 list-disc font-normal'>
            <li className='mt-2 font-normal'>Difficulty finding safe and accessible bike routes</li>
            <li className='mt-2 font-normal'>Lack of real-time information on road conditions and hazards</li>
            <li className='mt-2 font-normal'>Unpredictable weather affecting commute plans</li>
            <li className='mt-2 font-normal'>
              Limited engagement between the active commuting community and local businesses and authorities
            </li>
          </ul>
          <p className='mt-2 font-normal'>
            WeRide.Today addresses these challenges head-on, transforming the way people move through cities and
            fostering a more connected, sustainable urban environment.
          </p>
        </div>
        <h2 className='mt-6 text-2xl lg:text-3xl text-primary-1'>Empowering Communities for Safer, Smarter Cities</h2>
        <div className='lg:mr-48'>
          <p className='mt-2 font-normal'>
            WeRide.Today is more than just an app – it's a movement towards smarter, safer, and more enjoyable urban
            mobility. Our mission is to make active commuting the preferred choice for city dwellers, benefiting both
            individual health and the environment.
          </p>
          <div className='mt-2 h-56 sm:h-72 md:h-80 lg:h-96'>
            <iframe title='WeRide.Today' className='w-full h-full'
                    src='https://www.youtube.com/embed/VDkStRQWC1o?si=tw8bmFNRW4lyKV6V' />
          </div>
        </div>
      </Section>

      <Deco before after className='bg-primary-1' gridRow={3} />
      <Section className='z-20 mt-12 pt-12 pb-12 text-primary-2' gridRow={3}>
        <h2 className='mt-6 text-2xl lg:text-3xl'>Key Features</h2>
        <div className='lg:mr-48'>
          <p className='mt-2 font-normal'>
            WeRide.Today was designed around a few core functionalities that work together to provide a comprehensive
            solution for urban commuters.
          </p>
        </div>

        <div className='flex flex-wrap'>
          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Plan Your Safe Route</h3>
            <p className='mt-4 font-normal'>
              At the heart of WeRide.Today is a commitment to safe cycling. The app provides route recommendations using
              open-source map data service, giving cyclists confidence in their journeys.
            </p>
          </div>

          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Be Prepared</h3>
            <p className='mt-4 font-normal'>
              Weather is one of the biggest factors that stop people for going to work or school by bike. The app
              integrates real-time weather forecasts, ensuring cyclists are informed about upcoming conditions.
            </p>
          </div>

          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Help the Community</h3>
            <p className='mt-4 font-normal'>
              Cycling in a city can be unpredictable, which is why on-time updates are so crucial. WeRide.Today allows
              users to alert fellow commuters for dangers or obstacles by creating Hints, such as road closures,
              construction sites, or bad pavement. This crowd-sourced feedback loop builds a sense of community while
              improving the commuting experience for everyone.
            </p>
          </div>

          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Achieve Your Goals and Track Your Progress</h3>
            <p className='mt-4 font-normal'>
              The app allows users to set personal goals, such as distance covered or active time. Detailed activity
              reports track progress, giving users insights into their commuting habits as well as environmental impact.
              Users can see metrics like total distance, time spent riding, and calories burned, motivating them to stay
              active and improve their health.
            </p>
          </div>

          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>See Your Impact</h3>
            <p className='mt-4 font-normal'>
              Users can track CO2 savings and witness positive environmental impact.
            </p>
          </div>

          <div className='w-full md:w-1/2 p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Digital Footprint</h3>
            <p className='mt-4 font-normal'>
              Users can see and analyze their riding patterns with a personalized heatmap of their routes.
            </p>
          </div>

          <div className='w-full p-2'>
            <h3 className='mt-10 text-primary-2 font-bold'>Earn Rewards</h3>
            <p className='mt-4 font-normal'>
              To keep users engaged and motivated, WeRide.Today offers a gamified experience through rewards and badges.
              Cyclists can earn badges for reaching milestones like completing a set number of rides, contributing
              safety tips, or hitting fitness goals. This creates a fun, competitive element while encouraging positive
              habits like safe commuting and community involvement.
            </p>
          </div>
        </div>

        <h2 className='mt-6 text-2xl lg:text-3xl'>User Adoption and Success</h2>
        <div className='lg:mr-48'>
          <p className='mt-2 font-normal'>
            Since its launch in 2023, WeRide.Today has been recognized by many urban cyclists, and active commuters.
            WeRide.Today has seen a rapid increase in adoption, with users finding immense value in the combination of
            safety, community involvement, and personal achievement. Positive reviews have highlighted the app’s
            real-time hazard alerts, smooth user experience, and the rewards system, which keeps users engaged over
            time.
          </p>
        </div>
      </Section>

      <BackgroundWithGrid image='weride-hero' bottom={true} gridRow={4} />
      <Deco style={{ background: 'rgba(0, 0, 0, 0.4)' }} gridRow={4} className='z-0' />
      {/*<Deco className="bg-primary-2" decoType={2} gridRow={4} />*/}
      <Deco decoType={1} before inner after gridRow={4} className='z-20' />
      <Section className='z-20 mt-16 mb-20 pt-16 pb-20' gridRow={4}>
        <h2 className='col-span-3 text-2xl lg:text-3xl text-shadow text-center'>
          Join the Active Urban Mobility Revolution Today!
        </h2>
        <div className='col-span-3 sm:col-span-1'>
          <p className='mt-5 px-20 font-normal text-center'>
            Ready to transform your commute? Download WeRide.Today and be part of the movement towards safer, smarter,
            and more sustainable urban travel.
          </p>

          <div className='p-5 flex flex-col md:flex-row justify-center items-center'>
            <a href='https://apps.apple.com/us/app/weride-today/id6446196520'
               className='w-full sm:w-auto m-2'>
              <DownloadOnTheAppStoreButton className='w-full' />
            </a>

            <a href='https://play.google.com/store/apps/details?id=today.weride.mobile'
               className='w-full sm:w-auto m-2'>
              <GetItOnGooglePlayButton className='w-full' />
            </a>
          </div>
        </div>
      </Section>

      <Deco className='z-10 bg-primary-2' decoType={1} before gridRow={7} />
      <Nav className='z-20 text-primary-2' gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default ShowcaseWeRideTodayPage
